import React, { useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { CategoryCell, EntityCell, MemberCell, } from './CustomCell';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import ResearchTaskReportContext from 'context/ResearchTaskReportContext';

const useStyles = makeStyles(() => ({
	row: {
		'& .MuiTableCell-body': {
			borderBottomColor: '#17253F !important'
		}
	},
}));

const SumRow = ({ Cell, title, sum }) => {
	const { changes } = useContext(ResearchTaskReportContext);
	const classes = useStyles();
	return (
		<TableRow className={classes.row}>
			<Cell colSpan={5}>
				<TableLabel align="right">{title}</TableLabel>
			</Cell>
			<Cell>
				<NumberText fixedDecimalScale={true} value={sum?.netto_amount} />
			</Cell>
			<Cell>
				<NumberText fixedDecimalScale={true} value={sum?.vat_amount} />
			</Cell>
			<Cell>
				<NumberText fixedDecimalScale={true} value={sum?.eligible_amount} />
			</Cell>
			<Cell></Cell>
			<Cell></Cell>
			<Cell></Cell>
			{changes.report_b_new_columns_20250303 && <>
				<Cell></Cell>
				<Cell></Cell>
			</>}
		</TableRow>
	);
};

const CategorySumRow = props => <SumRow Cell={CategoryCell} {...props} />;
const EntitySumRow = props => <SumRow Cell={EntityCell} {...props} />;
const MemberSumRow = props => <SumRow Cell={MemberCell} {...props} />;

export default SumRow;
export { CategorySumRow, EntitySumRow, MemberSumRow };
