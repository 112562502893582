import React, { useEffect, useState } from 'react';
import { TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import TableLabel from 'components/Main/TableLabel';

import { EntitySumRow } from '../SumRow';
import { CategoryCell, EntityCell } from '../CustomCell';
import TableWithMembers from './TableWithMembers';
import TableWithoutMembers from './TableWithoutMembers';

const EntityTable = props => {
	const {
		categoryTitle,
		showMember = false,
		leader = false,
		entity,
		onChange,
		category
	} = props;
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const [data, setData] = useState(entity);

	useEffect(() => {
		if (_.isEqual(data, entity)) return;
		setData(entity);
	}, [entity]);

	useEffect(() => {
		if (_.isEqual(data, entity)) return;
		onChange(data);
	}, [data]);

	return (
		<>
			<TableRow>
				{leader &&
					<CategoryCell rowSpan={0}>
						<TableLabel>{categoryTitle}</TableLabel>
					</CategoryCell>
				}
				<EntityCell colSpan={5}>
					<TableLabel align="right">
						{t(leader ? 'Nazwa wnioskodawcy (Podmiot lidera)' : 'Nazwa Innej Jednostki')}
					</TableLabel>
				</EntityCell>
				<EntityCell colSpan={8}>
					<TableLabel style={{ textTransform: 'inherit' }}>{entity.entity_name}</TableLabel>
				</EntityCell>
			</TableRow>
			{showMember && <TableWithMembers entity={data} onChange={setData} category={category} />}
			{!showMember && <TableWithoutMembers entity={data} onChange={setData} category={category} />}
			<EntitySumRow title={t('Suma dla podmiotu')} sum={data.sum} />
		</>
	);
};

export default EntityTable;
