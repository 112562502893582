import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box, TableRow, TableCell, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Validator, { Boolean, Required, validate } from 'utils/Validator';
import PaginatedTable from 'components/PaginatedTable';
import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';
import AddingRow from './AddingRow';
import EditingRow from './EditingRow';
import UploadButton from 'components/Buttons/UploadButton';
import { useParams } from 'react-router-dom';
import API from 'apis/API';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const useStyles = makeStyles(() => ({
	table: {
		padding: 0,
	},
	button: {
		marginRight: 10,
	},
}));

const Table = () => {
	const { id: reportId } = useParams();
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const columns = [
		{ title: 'LP.', name: 'id', disableSort: true, },
		{ title: t('Imię i nazwisko'), name: 'name', disableSort: true, },
		{
			title: <>
				<TableLabel>{t('Podmiot Lidera/Członka Zespołu')}</TableLabel>
				<Typography>({t('Nazwa skrócona')})</Typography>
			</>,
			name: 'entity',
			disableSort: true,
		},
		{ title: t('Pracownik zaplanowany we wniosku'), name: 'planned_employee', disableSort: true, width: 150 },
		{ title: t('Rola w zespole badawczym'), name: 'position_type', disableSort: true, },
		{
			title: <>
				<TableLabel style={{ marginBottom: 10 }}>
					{t('Wymiar zaangażowania w Zadaniu Badawczym (w FTE/ EPC)')}
				</TableLabel>
				<Grid container spacing={1}>
					<Grid item xs>
						<TableLabel>{t('Planowany')}</TableLabel>
					</Grid>
					<Grid item xs>
						<TableLabel>{t('Rzeczywisty')}</TableLabel>
					</Grid>
				</Grid>
			</>,
			name: 'involvement_dimension',
			disableSort: true,
		},
		{ title: t('Akcje'), name: 'action', disableSort: true, width: 100, },
	];
	const classes = useStyles();
	const { formData, onChange, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	const [data, setData] = useState([]);
	const [editingData, setEditingData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [adding, setAdding] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);

	const Validators = {
		name: new Validator(Required),
		entity: new Validator(Required),
		planned_employee: new Validator(Boolean),
		position_type: new Validator(Required),
		involvement_dimension_planned: new Validator(Required),
		involvement_dimension_actual: new Validator(Required),
		main_responsibilities: new Validator(Required),
	};

	useEffect(() => {
		if (_.isEqual(data, formData[selectedLanguage].research_team.research_team.data)) return;
		setData(formData[selectedLanguage].research_team.research_team.data);
	}, [selectedLanguage]);
	// }, [formData[selectedLanguage].research_team.research_team.data]); // but why?

	useEffect(() => {
		if (!editingData) {
			setErrors(null);
			return;
		}

		let _errors = validate(editingData, Validators);
		setErrors(_errors);
	}, [editingData]);

	const handleSetAdding = () => {
		if (editingData) return;
		setAdding(!adding);
	};

	const handleAdd = addData => () => {
		let _errors = validate(addData, Validators);
		setErrors(_errors);
		if (_errors) return;

		setData(prev => {
			prev.push({ ...addData, index: data.length });
			onChange('pl.research_team.research_team.data', prev);

			let enData = _.clone(formData.en.research_team.research_team.data);
			enData.push({ ...addData, index: data.length });
			onChange('en.research_team.research_team.data', enData);
			return [...prev];
		});
		setAdding(false);
	};

	const handleEdit = index => () => {
		if ((editingData && errors) || adding) return;
		setEditingData({ ...data[index], index });
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setEditingData(prev => ({ ...prev, [name]: value }));
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('pl.research_team.research_team.data', prev);

			let enData = _.clone(formData.en.research_team.research_team.data);
			enData.splice(index, 1);
			onChange('en.research_team.research_team.data', enData);

			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = () => {
		if (errors) return;
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange(`${selectedLanguage}.research_team.research_team.data`, prev);

			if(selectedLanguage === 'pl') {
				let enData = _.clone(formData.en.research_team.research_team.data);
				enData[editingData.index] = {
					...enData[editingData.index],
					..._.omit(editingData, ['position_type', 'main_responsibilities'])
				};
				onChange('en.research_team.research_team.data', enData);
			}

			return [...prev];
		});
		setEditingData(null);
	};

	const importXlsx = (e) => {
		setIsProcessing(true);

		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const _data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(_data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 'A' });

				let values = {
					'pl': [],
					'en': [],
				};
				const schema = {
					name: '',
					entity: '',
					planned_employee: '',
					position_type: '',
					involvement_dimension_planned: '',
					involvement_dimension_actual: '',
					main_responsibilities: '',
					en: {},
				};
				let item = { ...schema };
				let index = 0;

				for (const [i, row] of jsonData.entries()) {
					if (i <= 2) continue; // skip headers
					if (i % 2) {
						item.name = row['B'];
						item.entity = row['C'];
						item.planned_employee = ('' + row['D']).trim().toLowerCase() === 'tak';
						item.position_type = row['E'];
						item.involvement_dimension_planned = row['F'];
						item.involvement_dimension_actual = row['G'];
						item.en.main_responsibilities = row['H'];
					} else {
						item.main_responsibilities = row['C'];
						item.index = index++; // indexes were already here, so we keep them
						let pl = { ...item };
						delete pl.en;
						values['pl'].push(pl);
						values['en'].push({ ...pl, ...item.en });
						item = { ...schema }; // reset item data
					}
				}

				setData(() => {
					return values['pl'];
				});

				onChange('pl.research_team.research_team.data', values['pl']);
				formData.en.disable_grab_all = true; // workaround for this broken form madness
				onChange('en.research_team.research_team.data', values['en']);

				toast.success('Dane zaimportowane pomyślnie');

				setIsProcessing(false);
			};
			reader.readAsArrayBuffer(file);
		}
	};

	const exportXlsx = async () => {
		setIsProcessing(true);
		try {
			const res = await API.researchTasks.report.exportAteam(reportId, selectedLanguage);
			const link = document.createElement('a');
			link.href = URL.createObjectURL(new Blob([res.data]));
			link.setAttribute('download', 'Raport A - Zespół badawczy.xlsx');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toast.error('Nieoczekiwany błąd.');
		}
		setIsProcessing(false);
	};

	const renderRows = () => (
		data?.map((item, index) => (
			index === editingData?.index
				? <EditingRow
					key={index}
					item={editingData}
					onChange={handleChange}
					onSave={handleSave}
					onDelete={selectedLanguage === 'en' ? null : handleDelete(index)}
					errors={errors}
				/>
				: <React.Fragment key={index}>
					<TableRow>
						<TableCell>{index + 1}.</TableCell>
						<TableCell>
							<TableLabel>{item.name}</TableLabel>
						</TableCell>
						<TableCell>{item.entity}</TableCell>
						<TableCell>{item.planned_employee ? t('Tak') : t('Nie')}</TableCell>
						<TableCell>{item.position_type}</TableCell>
						<TableCell>
							<Grid container spacing={1}>
								<Grid item xs>
									<NumberText fixedDecimalScale={true} value={item.involvement_dimension_planned} />
								</Grid>
								<Grid item xs>
									<NumberText fixedDecimalScale={true} value={item.involvement_dimension_actual} />
								</Grid>
							</Grid>
						</TableCell>
						<TableCell>
							<Box display="flex">
								{!disabledInput &&
									<EditIconButton
										tooltip={t('Edytuj')}
										onClick={handleEdit(index)}
										disabled={disabledInput}
									/>
								}
								{!disabledInput && selectedLanguage !== 'en' &&
									<DeleteIconButton
										tooltip={t('Usuń')}
										onClick={handleDelete(index)}
										hideModal
										disabled={disabledInput || selectedLanguage === 'en'}
									/>
								}
							</Box>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>
							{t('Główne obowiązki w Zadaniu Badawczym')}
						</TableCell>
						<TableCell colSpan={4}>
							<Typography>{item.main_responsibilities}</Typography>
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</React.Fragment>
		)));

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
				<PrimaryButton
					variant="outlined"
					onClick={handleSetAdding}
					disabled={disabledInput || selectedLanguage === 'en'}
				>
					{adding ? t('Anuluj dodawanie') : t('Dodaj osobę')}
				</PrimaryButton>

				<Box>
					{reportId && (
						<PrimaryButton
							variant="outlined"
							className={classes.button}
							onClick={exportXlsx}
							disabled={isProcessing || selectedLanguage === 'en'}
						>
							Export
						</PrimaryButton>
					)}

					<UploadButton
						acceptFiles=".xlsx"
						handleCapture={importXlsx}
						disabled={isProcessing || selectedLanguage === 'en'}
						variant="outlined"
					>
						Import
					</UploadButton>
				</Box>
			</Box>

			<div id="research-task-a">
				<PaginatedTable
					containerClassName={classes.table}
					columns={columns}
					renderRows={renderRows}
					filterRow={
						adding ? <AddingRow errors={errors} onAdd={handleAdd} /> : <></>
					}
				/>
			</div>
		</>
	);
};

export default Table;
