import React, { useContext, useEffect, useState } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';

import { MemberCell } from '../../CustomCell';
import { MemberSumRow } from '../../SumRow';
import EditingRow from '../../EditingRow';
import ResearchTaskReportContext from 'context/ResearchTaskReportContext';

const MemberTable = ({ member, onChange, onEdit, onDelete, disabled, selectedLanguage }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const [data, setData] = useState(member.data);
	const [sum, setSum] = useState(member.sum);
	const [editingIndex, setEditingIndex] = useState(null);
	const [deletedIndex, setDeletedIndex] = useState(-1);
	const { changes } = useContext(ResearchTaskReportContext);

	useEffect(() => {
		if (_.isEqual(data, member.data)) return;
		setData(member.data);
		setDeletedIndex(-1);
	}, [member.data]);

	useEffect(() => {
		let _sum = {
			netto_amount: parseFloat(_.sumBy(data, 'netto_amount')),
			vat_amount: parseFloat(_.sumBy(data, 'vat_amount')),
			eligible_amount: parseFloat(_.sumBy(data, 'eligible_amount')),
		};

		if (!_.isEqual(sum, _sum)) {
			setSum(_sum);
		}

		let _member = {
			..._.cloneDeep(member),
			sum: _sum,
			data,
		};
		if (_.isEqual(member, _member)) return;

		onChange(_.cloneDeep(_member), deletedIndex);
	}, [data]);

	const handleAdd = () => {
		if (editingIndex !== null) return;

		setData(prev => {
			prev.push({
				document_number: '',
				registration_number: '',
				document_date: '',
				payment_date: '',
				netto_amount: 0,
				vat_amount: 0,
				eligible_amount: 0,
				service_description: '',
				research_task_stage_nb: '',
				cost_estimate_item: '',
				document_issuer: '',
			});
			setEditingIndex(prev.length - 1);
			return [...prev];
		});
	};

	// Delete member's data
	const handleDelete = i => () => {
		setDeletedIndex(i);
		setData(prev => {
			prev.splice(i, 1);
			return [...prev];
		});

		setEditingIndex(prev => {
			if (prev === i) return null;
			if (prev > i) return i - 1;
			return prev;
		});
	};

	const handleSave = item => {
		setData(prev => {
			prev[editingIndex] = item;
			setEditingIndex(null);
			return [...prev];
		});
	};

	return (
		<>
			<TableRow>
				<MemberCell colSpan={5} style={{ verticalAlign: 'middle' }}>
					<TableLabel align="right">
						{t(member.member_type_id === 1 ? 'Imię i nazwisko lidera' : 'Imię i nazwisko członka zespołu')}
					</TableLabel>
				</MemberCell>
				<MemberCell colSpan={changes.report_b_new_columns_20250303 ? 7 : 5}>{member.name}</MemberCell>
				<MemberCell>
					{!disabled && selectedLanguage !== 'en' &&
						<Box display="flex">
							<EditIconButton
								tooltip={t('Edytuj')}
								onClick={onEdit}
								disabled={disabled || selectedLanguage === 'en'}
							/>
							<DeleteIconButton
								tooltip={t('Usuń')}
								onClick={onDelete}
								disabled={disabled || selectedLanguage === 'en'}
							/>
						</Box>
					}
				</MemberCell>
			</TableRow>
			{data?.map((item, i) => (
				i === editingIndex
					? <EditingRow
						key={i}
						index={i}
						item={item}
						onSave={handleSave}
						onDelete={handleDelete}
						selectedLanguage={selectedLanguage}
					/>
					: <TableRow key={i}>
						<TableCell>{i + 1}.</TableCell>
						<TableCell>{item.document_number}</TableCell>
						<TableCell>{item.registration_number}</TableCell>
						<TableCell>{item.document_date}</TableCell>
						<TableCell>{item.payment_date}</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.netto_amount} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.vat_amount} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.eligible_amount} />
						</TableCell>
						<TableCell>{item.service_description}</TableCell>
						{changes.report_b_new_columns_20250303 && <>
							<TableCell>{item.research_task_stage_nb}</TableCell>
							<TableCell>{item.cost_estimate_item}</TableCell>
						</>}
						<TableCell>{item.document_issuer}</TableCell>
						<TableCell>
							<Box display="flex">
								{!disabled &&
									<EditIconButton
										tooltip={t('Edytuj')}
										onClick={() => setEditingIndex(i)}
										disabled={disabled}
									/>
								}
								{!disabled && selectedLanguage !== 'en' &&
									<DeleteIconButton
										tooltip={t('Usuń')}
										onClick={handleDelete(i)}
										disabled={disabled || selectedLanguage === 'en'}
									/>
								}
							</Box>
						</TableCell>
					</TableRow>
			))}
			<TableRow>
				<TableCell colSpan={13}>
					<PrimaryButton onClick={handleAdd} disabled={disabled || selectedLanguage === 'en'}>
						{t('Dodaj kolejny wiersz')}
					</PrimaryButton>
				</TableCell>
			</TableRow>
			<MemberSumRow title={t('Suma dla osoby')} sum={sum} />
		</>
	);
};

export default MemberTable;
