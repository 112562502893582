import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';

import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';
import { CategoryCell, HeadCell, SumCell } from '../CustomCell';
import AddModal from '../AddModal';
import { getNewTask, getSum, getSumOfResearchTasks } from './utils';
import Task from './Task';

const useStyles = makeStyles(() => ({
	lastRow: {
		'& .MuiTableCell-body': {
			borderBottom: '1px solid #17253F !important',
		}
	}
}));

const Table1 = () => {
	const classes = useStyles();
	const { id } = useParams();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { loading, researchTasks, publishedInformations, formData, onChange, errors, isPreview, changes } = useContext(ManagementDetailedInformationsContext);
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		if (!id || loading || _.isEqual(tasks, formData.table1.research_tasks)) return;
		setTasks([...(formData.table1.research_tasks || [])]);
	}, [loading]);

	useEffect(() => {
		onChange('table1.sum', getSumOfResearchTasks(tasks));

		if (_.isEqual(tasks, formData.table1.research_tasks)) return;
		onChange('table1.research_tasks', tasks);
	}, [tasks]);

	const handleAddTask = taskId => {
		const task = _.find(researchTasks, (t => +t.id === +taskId));
		setTasks(prev => {
			prev.push(getNewTask(task, publishedInformations, changes));
			return [...prev];
		});
	};

	const handleChange = index => e => {
		let { name, value } = e.target;

		setTasks(prev => {
			let _task = prev[index];
			if (name === 'sum.funds_paid') {
				let totalName = 'sum.total_fund_paid';
				value = +value;
				_.set(_task, totalName, (_.get(_task, totalName) - _.get(_task, name) + value));
			}

			if (changes.hide_expenditure_reported_columns_20250317) {
				if (_.endsWith(name, '.district_approved_expenditure')) {
					const accumulated = _.get(_task, name.replace('district_approved_expenditure', 'total_approved_expenditure_accumulated'));
					_.set(_task, name.replace('district_approved_expenditure', 'total_approved_expenditure'), accumulated + value);
				}
			}

			_.set(_task, name, value);
			let names = _.split(name, '.', 2);
			if (!_.includes(['sum.funds_paid', 'comment'], name)) {
				_task.sum[names[1]] = getSum(_task, names[1]);
			}
			prev[index] = _task;
			return [...prev];
		});
	};

	const handleDelete = index => () => {
		setTasks(prev => {
			prev.splice(index, 1);
			return [...prev];
		});
	};

	return (
		<>
			<TableHead>
				<TableRow>
					<CategoryCell style={{ borderBottomWidth: 0 }}></CategoryCell>
					<CategoryCell style={{ borderBottomWidth: 0 }}></CategoryCell>
					<HeadCell>
						<TableLabel>{t('Rodzaj środków (Zespoły Badawcze)')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wysokość zakontraktowa')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('WYPŁACONE (ZAWNIOSKOWANE PRZEZ ZESPOŁY BADAWCZE) ŚRODKI łącznie')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wypłacone (zawnioskowane przez zespoły badawcze) środki w bieżącym okresie')}</TableLabel>
					</HeadCell>
					{!changes.hide_expenditure_reported_columns_20250317 && <>
						<HeadCell>
							<TableLabel>{t('Wydatki zaraportowane PRZEZ ZESPOŁY BADAWCZE łącznie')}</TableLabel>
						</HeadCell>
						<HeadCell>
							<TableLabel>{t('Wydatki zaraportowane przez zespoły badawcze w bieżącym okresie')}</TableLabel>
						</HeadCell>
					</>}
					<HeadCell>
						<TableLabel>{t('Wydatki zatwierdzone łącznie')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wydatki zatwierdzone w bieżącym okr.')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('pROCENT wykorzystania środków')}</TableLabel>
					</HeadCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<CategoryCell rowSpan={0} style={{ borderTopWidth: 0 }}>
						<TableLabel>A</TableLabel>
					</CategoryCell>
					<CategoryCell rowSpan={0} style={{ borderTopWidth: 0 }}>
						<TableLabel>1</TableLabel>
					</CategoryCell>
				</TableRow>
				<TableRow>
					<SumCell>
						<TableLabel>{t('Środki finansowe przeznaczone na finansowanie działalności naukowej poszczególnych Zespołów badawczych')}</TableLabel>
					</SumCell>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.contract_amount} />
					</SumCell>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.total_fund_paid} />
					</SumCell>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.funds_paid} />
					</SumCell>
					{!changes.hide_expenditure_reported_columns_20250317 && <>
						<SumCell>
							<NumberText fixedDecimalScale={true} value={formData.table1.sum.total_expenditure_reported} />
						</SumCell>
						<SumCell>
							<NumberText fixedDecimalScale={true} value={formData.table1.sum.expenditure_reported} />
						</SumCell>
					</>}
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.total_approved_expenditure} />
					</SumCell>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.district_approved_expenditure} />
					</SumCell>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table1.sum.funds_use_percent} />
					</SumCell>
				</TableRow>
				<TableRow>
					<TableCell colSpan={changes.hide_expenditure_reported_columns_20250317 ? 7 : 9}>
						{t('w tym')}
					</TableCell>
				</TableRow>
				{tasks.map((task, i) =>
					<Task
						key={i}
						task={task}
						onChange={handleChange(i)}
						onDelete={handleDelete(i)}
						errors={errors?.table1?.research_tasks?.[i]}
						disabled={isPreview}
					/>
				)}
				<TableRow className={classes.lastRow}>
					<TableCell colSpan={9}>
						<AddModal
							excludingIds={_.map(tasks, 'research_task_id')}
							onAdd={handleAddTask}
							disabled={isPreview}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</>
	);
};

export default Table1;
