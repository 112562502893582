import React, { useEffect, useState } from 'react';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import API from 'apis/API';
import useAsyncError from 'hooks/AsyncError';
import moment from 'moment-timezone';

const ResearchTaskReportContext = React.createContext(null);

export const Provider = ({ children }) => {
	const throwError = useAsyncError();
	const { task_id, type } = useParams();
	const history = useHistory();
	const { i18n } = useTranslation();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const [researchTask, setResearchTask] = useState({
		wib_manager: {
			name: '',
			headquarters_street: '',
			headquarters_building_number: '',
			headquarters_apartment_number: '',
			headquarters_postcode: '',
			headquarters_city: '',
		},
		entities: {
			leader: {
				entity_name: '',
				entity_short_name: '',
				headquarters_street: '',
				headquarters_building_number: '',
				headquarters_apartment_number: '',
				headquarters_postcode: '',
				headquarters_city: '',
				entity_manager_firstname: '',
				entity_manager_surname: '',
				entity_manager_position: '',
				phone_number: '',
				website_address: '',
				email_address: '',
				esp_address: '',
				correspondence_street: '',
				correspondence_building_number: '',
				correspondence_apartment_number: '',
				correspondence_postcode: '',
				correspondence_city: '',
			},
			consortium_member: [],
		},
		financial_agreement_number: '',
		financial_agreement_signing_date: '',
		contacts: [{
			name: '',
			email: '',
			phone_number: '',
		}],
		team_leader: {
			name: '',
			email: '',
			phone_number: '',
		},
		team_members: []
	});
	const [selectedLanguage, setSelectedLanguage] = useState('pl');
	const [mainInfo, setMainInfo] = useState({
		period_type: 'periodic',
		period_from: '',
		period_to: '',
		research_task_id: task_id,
		type: type || 'type_a'
	});
	const [changes, setChanges] = useState({});

	useEffect(() => {
		API.researchTasks.report.getResearchTask(task_id).then(res => {
			let data = res.data.data;
			setResearchTask(data);
			if (data.status !== 'published') history.goBack();
		}).catch(err => {
			throwError(new Error(err));
		});
	}, [task_id]);

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
		return () => {
			i18n.changeLanguage('pl');
		};
	}, [selectedLanguage]);

	const onChangeMainInfo = e => {
		const { name, value } = e.target;
		setMainInfo(prev => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		setChanges(prev => {
			// new columns: research_task_stage_nb & cost_estimate_item
			prev.report_b_new_columns_20250303 = moment(mainInfo.created_at).diff('2025-03-03', 'days') >= 0;
			// add new variable changes here
			return prev;
		});
	}, [mainInfo.created_at]);

	const value = {
		isPreview,
		selectedLanguage,
		setSelectedLanguage,
		researchTask,
		mainInfo,
		setMainInfo,
		onChangeMainInfo,
		changes,
	};

	return (
		<ResearchTaskReportContext.Provider value={value}>
			{children}
		</ResearchTaskReportContext.Provider>
	);
};

export const ResearchTaskReportContextProvider = withRouter(Provider);

export default ResearchTaskReportContext;
