import React, { useContext } from 'react';
import { makeStyles, Box, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DeleteIconButton } from 'components/Buttons/IconButtons';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import FormInput from 'components/Form/FormInput';
import NumberInput from 'components/Form/NumberInput';

import { HeadCell } from '../CustomCell';
import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';

const useStyles = makeStyles(() => ({
	row: {
		'& .MuiTableCell-body:not(:first-child)': {
			color: '#6a6a6a'
		}
	},
	lastRow: {
		'& .MuiTableCell-body': {
			borderBottom: '1px solid #17253F !important',
		}
	},
	charCount: {
		textAlign: 'right',
	},
}));

const Task = ({ task, onChange, onDelete, errors, disabled }) => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { changes } = useContext(ManagementDetailedInformationsContext);
	return (
		<>
			<TableRow className={classes.row}>
				<TableCell>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<TableLabel>
							{t('Zespół badawczy')} {task.research_task_acronym}
						</TableLabel>
						<DeleteIconButton onClick={onDelete} tooltip="Usuń" disabled={disabled} />
					</Box>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.sum.contract_amount} />
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.sum.total_fund_paid} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="sum.funds_paid"
						value={task.sum.funds_paid}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.sum.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.sum.expenditure_reported} />
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.sum.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.sum.district_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.sum.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.row}>
				<TableCell>{t('Wynagrodzenia')}</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.salary.contract_amount} />
				</TableCell>
				<HeadCell rowSpan={5}></HeadCell>
				<HeadCell rowSpan={5}></HeadCell>
				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.salary.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberInput
							name="salary.expenditure_reported"
							value={task.salary.expenditure_reported}
							onChange={onChange}
							disabled={disabled}
						/>
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.salary.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="salary.district_approved_expenditure"
						value={task.salary.district_approved_expenditure}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.salary.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.row}>
				<TableCell>{t('Koszty aparatury naukowo-badawczej, środków trwałych i wartości niematerialnych i prawnych, w tym zakupy, leasing, odpisy amortyzacyjne i koszty odpłatnego korzystania')}</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.equipment_costs.contract_amount} />
				</TableCell>
				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.equipment_costs.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberInput
							name="equipment_costs.expenditure_reported"
							value={task.equipment_costs.expenditure_reported}
							onChange={onChange}
							disabled={disabled}
						/>
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.equipment_costs.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="equipment_costs.district_approved_expenditure"
						value={task.equipment_costs.district_approved_expenditure}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.equipment_costs.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.row}>
				<TableCell>{t('Koszty podwykonawstwa i usług obcych')}</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.external_service_costs.contract_amount} />
				</TableCell>
				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.external_service_costs.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberInput
							name="external_service_costs.expenditure_reported"
							value={task.external_service_costs.expenditure_reported}
							onChange={onChange}
							disabled={disabled}
						/>
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.external_service_costs.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="external_service_costs.district_approved_expenditure"
						value={task.external_service_costs.district_approved_expenditure}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.external_service_costs.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.row}>
				<TableCell>{t('Inne koszty bezpośrednie')}</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.other_direct_costs.contract_amount} />
				</TableCell>

				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.other_direct_costs.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberInput
							name="other_direct_costs.expenditure_reported"
							value={task.other_direct_costs.expenditure_reported}
							onChange={onChange}
							disabled={disabled}
						/>
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.other_direct_costs.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="other_direct_costs.district_approved_expenditure"
						value={task.other_direct_costs.district_approved_expenditure}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.other_direct_costs.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.row}>
				<TableCell>{t('koszty pośrednie')}</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.indirect_costs.contract_amount} />
				</TableCell>

				{!changes.hide_expenditure_reported_columns_20250317 && <>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={task.indirect_costs.total_expenditure_reported} />
					</TableCell>
					<TableCell>
						<NumberInput
							name="indirect_costs.expenditure_reported"
							value={task.indirect_costs.expenditure_reported}
							onChange={onChange}
							disabled={disabled}
						/>
					</TableCell>
				</>}
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.indirect_costs.total_approved_expenditure} />
				</TableCell>
				<TableCell>
					<NumberInput
						name="indirect_costs.district_approved_expenditure"
						value={task.indirect_costs.district_approved_expenditure}
						onChange={onChange}
						disabled={disabled}
					/>
				</TableCell>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={task.indirect_costs.funds_use_percent} />
				</TableCell>
			</TableRow>
			<TableRow className={classes.lastRow}>
				<TableCell>
					{t('Komentarz')}
				</TableCell>
				<TableCell colSpan={changes.hide_expenditure_reported_columns_20250317 ? 6 : 8}>
					<FormInput
						placeholder={t('Wpisz Komentarz')}
						name="comment"
						value={task.comment}
						onChange={onChange}
						multiline
						minRows={10}
						maxRows={10}
						gutterBottom={false}
						inputProps={{ maxLength: 2000, 'aria-required': true }}
						FormHelperTextProps={(errors?.comment && !task.comment)
							? null
							: {
								error: false,
								classes: { root: classes.charCount }
							}
						}
						errorState={Boolean(errors?.comment && !task.comment)}
						error={!task.comment && errors?.comment
							? errors?.comment
							: `${task.comment?.length || 0}/2000 (max)`
						}
						disabled={disabled}
					/>
				</TableCell>
			</TableRow>
		</>
	);
};

export default Task;
